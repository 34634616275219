import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  class: "barcode",
  ref: "barcode"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    class: "text-center",
    href: _ctx.clickable ? _ctx.value : 'javascript:void(0)',
    target: "_blank"
  }, [
    _createElementVNode("canvas", _hoisted_2, null, 512 /* NEED_PATCH */)
  ], 8 /* PROPS */, _hoisted_1))
}