
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import qrcode from 'qrcode';

@Options({})
export default class QRCode extends Vue {
  @Prop(String) value!: string;
  @Prop({ type: Boolean, default: true }) clickable!: boolean;

  @Watch('value')
  onValueChange(): void {
    this.setQRValue(this.value);
  }

  mounted(): void {
    this.setQRValue(this.value);
  }

  setQRValue(value: string): void {
    qrcode.toCanvas(this.$refs.barcode, value, (error) => {
      if (error) console.error(error);
    });
  }
}
