
import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class Dialog extends Vue {
  @Prop(Boolean) show = false;
  @Watch('show') onShowChange(): void {
    if (this.show) this.showDialog();
    else this.close();
  }

  private visible = false;
  private subscription!: Subscription;

  get dialogWrapper(): HTMLElement {
    return this.$refs.dialogWrapper as HTMLElement;
  }

  created(): void {
    this.onShowChange();
  }

  showDialog(): void {
    this.visible = true;

    // Allows us to clear the subscriptions later easily
    this.subscription = new Subscription();

    // Allow closing the dialog using the escape key
    this.registerDialogEvents();
  }

  close(): void {
    this.visible = false;

    // Clear the existing subscriptions
    if (this.subscription) this.subscription.unsubscribe();
  }

  private registerDialogEvents() {
    this.subscription.add(
      fromEvent(document, 'keydown')
        .pipe(filter((event) => (event as KeyboardEvent).key === 'Escape'))
        .subscribe(() => {
          this.close();
        })
    );

    this.subscription.add(
      fromEvent(document, 'click')
        .pipe(
          filter((event) => {
            // Check if we are clicking on the dialog mask behind and not on the dialog body
            const targetEl = event.target as HTMLElement;
            return targetEl.classList.contains('dialog');
          })
        )
        .subscribe(() => this.close())
    );
  }
}
