
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

type ToastyState = 'error' | 'info' | 'loading' | 'none';

export interface ToastyOptions {
  state?: ToastyState;
  html?: string;
  showTime?: number;
}

@Options({})
export default class SimpleToasty extends Vue {
  private visible = false;
  private state: ToastyState = 'info';

  @Prop(Boolean) show = false;

  @Prop({ type: String, default: 'info' }) defaultState!: ToastyState;

  @Watch('show') visibleChange(): void {
    if (this.visible) this.showToasty();
  }

  @Prop({
    type: Number,
    default: 3000,
  })
  showTime!: number;

  private timer!: number | null;
  private customHtml: string | null = null;

  mounted(): void {
    // If we should show this toasty on load, show it
    if (this.show) this.showToasty();
  }

  showToasty(options?: ToastyOptions | string): void {
    // If a timer exists, remove the current timer
    this.clearTimer();

    // If it's only a string, convert it into a toasty options
    if (typeof options === 'string') options = { html: options };

    this.customHtml = options?.html || null;
    this.state = options?.state || this.defaultState;
    const showTime = options?.showTime || this.showTime;

    // Show the toasty
    this.visible = true;

    // If there is a show time specified, hide the toasty after that time
    if (showTime > -1)
      setTimeout(() => {
        this.hide();
      }, options?.showTime || this.showTime);
  }

  hide(): void {
    this.visible = false;
    this.clearTimer();
    this.customHtml = null;
  }

  private clearTimer(): void {
    if (this.timer) clearTimeout(this.timer);
    this.timer = null;
  }
}
