
import { Options, Vue } from 'vue-class-component';
import OverlayedPopup from '@/components/OverlayedPopup.vue';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
import socketClient from '@/services/socket-client.service';
import { Prop } from 'vue-property-decorator';
import { Subscription } from 'rxjs';
import { PlaybackInfo } from '../../../shared/models/common-models';
import { filter } from 'rxjs/operators';
import QRCode from '@/components/QRCode.vue';
import { event } from 'vue-gtag';

import { SocketRemoteActionMsgPayload } from '../../../shared/models/socket-events';
import AppService from '@/services/app.service';
import FilesSelector from '@/components/FilesSelector.vue';
import { applyControllerAction } from '@/utils/player-controller-helper';
import LoadingImage from '@/components/LoadingImage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

interface PlayableFiles {
  videoFile: File | null;
  subtitlesFile: File | null;
}

const packageVersion = process.env.PACKAGE_VERSION as string;

@Options({
  components: {
    VideoPlayer,
    OverlayedPopup,
    QRCode,
    FilesSelector,
    LoadingImage,
    FontAwesomeIcon,
  },
})
export default class ChromeCaster extends Vue {
  private subscription = new Subscription();

  @Prop(String) sessionId!: string;
  videoFile: File | null = null;
  subtitlesFile: File | null = null;

  get videoPlayer(): VideoPlayer {
    return this.$refs.videoPlayer as VideoPlayer;
  }

  get qrURL(): string {
    return AppService.qrURL;
  }

  get version(): string {
    return packageVersion;
  }

  onFilesDragged(files: FileList | undefined): void {
    event('media-files-dragged', { event_category: 'media-change' });
    this.onFilesToImport(files);
  }

  onVideoFileSelected(files: FileList | undefined): void {
    event('media-files-selected', { event_category: 'media-change' });
    this.onFilesToImport(files);
  }

  async onFilesToImport(files: FileList | undefined): Promise<void> {
    const playableFiles = await this.getPlayableFiles(files);

    if (playableFiles.videoFile) this.videoFile = playableFiles.videoFile;
    if (playableFiles.subtitlesFile)
      this.subtitlesFile = playableFiles.subtitlesFile;
  }

  async getPlayableFiles(files: FileList | undefined): Promise<PlayableFiles> {
    const playableFiles: PlayableFiles = {
      videoFile: null,
      subtitlesFile: null,
    };

    if (files) {
      for (const file of files) {
        if (this.videoPlayer.canPlayFile(file)) {
          playableFiles.videoFile = file;
        } else if (this.videoPlayer.supportsSubtitles(file)) {
          playableFiles.subtitlesFile = file;
        }
      }
    }

    return playableFiles;
  }

  onMediaChanged(): void {
    this.notifyAboutMediaChange();
  }

  onSubtitlesChanged(): void {
    this.notifyAboutMediaChange();
  }

  onVideoPlayStateChanged(event: Event): void {
    const isPlaying = event.type === 'playing';

    // Notify when play state changed
    socketClient.sendMessage({
      type: 'media-playback',
      payload: { isPlaying },
    });
  }

  onVideoPlaytimeChanged(event: Event): void {
    const videoElement = event.target as HTMLVideoElement;
    socketClient.sendMessage({
      type: 'media-playback',
      payload: {
        currentTime: videoElement.currentTime,
        duration: videoElement.duration,
      },
    });
  }

  notifyAboutMediaChange(): void {
    const playbackInfo: PlaybackInfo = {
      videoName: this.videoPlayer.playedVideoFile?.name,
      subtitlesName: this.videoPlayer.playedVideoSubtitlesFile?.name || '',
      duration: this.videoPlayer.duration,
      currentTime: this.videoPlayer.currentTime,
    };

    // Now notify the server that the media was changed
    socketClient.sendMessage({ type: 'media-playback', payload: playbackInfo });
  }

  mounted(): void {
    socketClient.connect(this.sessionId, 'player');
    this.subscription.add(this.handleRemoteMessages());
  }

  private handleRemoteMessages(): Subscription {
    return socketClient.message
      .pipe(filter((msg) => msg.type === 'remote-action'))
      .subscribe((msg) => {
        const payload = msg.payload as SocketRemoteActionMsgPayload;

        // Applies a controller action
        applyControllerAction(
          this.videoPlayer.controller,
          payload.actionName,
          payload.actionValue
        );
      });
  }

  unmounted(): void {
    socketClient.disconnect();
    this.subscription.unsubscribe();
  }
}
