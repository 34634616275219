import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_VTooltip = _resolveComponent("VTooltip")!

  return (_openBlock(), _createBlock(_component_VTooltip, null, {
    popper: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.tooltipText), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: "javascript: void(0);",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
        class: _normalizeClass(["cast-button", [_ctx.castState]])
      }, [
        _createVNode(_component_font_awesome_icon, { icon: ['fab', 'chromecast'] })
      ], 2 /* CLASS */)
    ]),
    _: 1 /* STABLE */
  }))
}