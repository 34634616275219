import {
  SUBTITLE_OFFSET_ACTION,
  SUBTITLE_SIZE_ACTION,
  VOLUME_ACTION,
} from '../../../shared/models/common-models';
import { PlayerController } from '../../../shared/models/common-models';
import { CONTROLLER_ACTION } from '../../../shared/models/socket-events';

export async function applyControllerAction(
  controller: PlayerController,
  action: CONTROLLER_ACTION,
  actionValue?: string | number
): Promise<void> {
  switch (action) {
    case 'play-pause':
      return controller.playPause();
    case 'backward':
      return controller.backward(actionValue as number);
    case 'forward':
      return controller.forward(actionValue as number);

    case 'change-currenttime':
      controller.currentTime = actionValue as number;
      return;

    case 'subtitles-size':
      return controller.changeSubtitlesSize(
        actionValue as SUBTITLE_SIZE_ACTION
      );
    case 'subtitles-offset':
      return controller.changeSubtitlesOffset(
        actionValue as SUBTITLE_OFFSET_ACTION
      );
    case 'subtitles-reset':
      return controller.resetSubtitlesStyling();

    case 'toggle-fullscreen':
      return controller.toggleFullscreen();

    case 'change-volume':
      return controller.changeVolume(actionValue as VOLUME_ACTION);
  }

  throw new Error('Not implemented!');
}
