import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "dialogWrapper",
  class: "dialog p-2"
}
const _hoisted_2 = {
  tabindex: "-1",
  class: "dialog-body d-flex flex-column shadow p-3"
}
const _hoisted_3 = { class: "dialog-header mb-3 d-flex align-items-center justify-content-center" }
const _hoisted_4 = { class: "dialog-content flex-fill mb-3" }
const _hoisted_5 = { class: "dialog-footer d-flex align-items-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header")
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "content")
              ]),
              _createElementVNode("div", _hoisted_5, [
                _renderSlot(_ctx.$slots, "footer")
              ])
            ])
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }))
}