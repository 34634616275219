
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from 'vue-class-component';
import { event } from 'vue-gtag';

/* eslint-disable */
declare var cast: any;
/* eslint-disable */
declare var chrome: any;

const appId = process.env.VUE_APP_CAST_APPID as string;

type CAST_STATE_TYPE =
  | 'NO_DEVICES_AVAILABLE'
  | 'NOT_CONNECTED'
  | 'CONNECTING'
  | 'CONNECTED';

@Options({ components: { FontAwesomeIcon } })
export default class CastButton extends Vue {
  mounted(): void {
    this.initCastApi();
  }

  get isCasting(): boolean {
    return this.castState === 'CONNECTED';
  }

  /* eslint-disable */
  get castSession(): any {
    return this.castInstance.getCurrentSession();
  }

  /* eslint-disable */
  private castInstance: any = null;
  private castState: CAST_STATE_TYPE = 'NO_DEVICES_AVAILABLE';

  get tooltipText(): string {
    switch (this.castState) {
      case 'NO_DEVICES_AVAILABLE':
        return 'Cast unavailable';
      case 'NOT_CONNECTED':
        return 'Start casting';
      default:
        return 'Stop casting';
    }
  }

  initCastApi(): Promise<void> {
    (window as any)['__onGCastApiAvailable'] = (isAvailable: boolean) => {
      if (isAvailable) {
        // Casting is available, setup the casting api
        this.setupCastApi();
        this.castState = 'NOT_CONNECTED';
      } else {
        this.castState = 'NO_DEVICES_AVAILABLE';
      }
    };

    // As said in docs, we need to attach the cast api script afterwards
    return this.appendCastApiScriptAndWaitForLoad();
  }

  onClick(): void {
    event('click', {
      event_category: 'cast',
      event_label: !this.isCasting ? 'start-casting' : 'stop-casting',
    });

    if (!this.isCasting) this.startCasting();
    else this.stopCasting();
  }

  private async startCasting(): Promise<void> {
    if (this.castSession) this.stopCasting();
    await this.castInstance.requestSession();
  }

  private stopCasting() {
    if (this.castSession) this.castSession.endSession(true);
  }

  private setupCastApi(): void {
    this.castInstance = cast.framework.CastContext.getInstance();
    this.castInstance.setOptions({
      receiverApplicationId: appId, //chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      androidReceiverCompatible: true,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });

    this.castInstance.addEventListener(
      cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
      (event: { sessionState: string }) => {
        const { sessionState } = event;

        switch (sessionState) {
          case 'SESSION_STARTED':
            this.castState = 'CONNECTING';
            break;

          case 'SESSION_RESUMED':
            this.castState = 'CONNECTED';
            break;

          case 'SESSION_ENDED':
            this.castState = 'NOT_CONNECTED';
            break;
        }

        console.log(event);
      }
    );
  }

  private async appendCastApiScriptAndWaitForLoad(): Promise<void> {
    return new Promise<void>((resolve) => {
      const castApiScript = document.createElement('script');
      castApiScript.type = 'text/javascript';
      castApiScript.src =
        'https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1';
      castApiScript.async = false;
      castApiScript.addEventListener('load', () => resolve());

      document.body.appendChild(castApiScript);
    });
  }
}
