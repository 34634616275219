
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({ components: { FontAwesomeIcon } })
export default class LoadingImage extends Vue {
  @Prop(String) url!: string;
  @Prop({ type: String, default: 'auto' }) width!: string;
  @Prop({ type: String, default: 'auto' }) height!: string;

  isLoading = true;
  failedToLoad = false;

  onImageLoaded(): void {
    this.isLoading = false;
  }

  onImageError(): void {
    this.failedToLoad = true;
    this.isLoading = false;
  }
}
