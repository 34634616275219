import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-selector",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSelectFile && _ctx.onSelectFile(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("input", _mergeProps({ type: "file" }, { multiple: _ctx.allowMultipleFiles }, {
      ref: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFileChange && _ctx.onFileChange(...args)))
    }), null, 16 /* FULL_PROPS */)
  ]))
}