
import SimpleToasty from '@/components/SimpleToasty.vue';
import SubtitlesService, { CssRules } from '@/services/subtitles.service';
import { SubtitleManager } from '@/utils/subtitle-manager';
import { Options, Vue } from 'vue-class-component';

import {
  PlayerController,
  SUBTITLE_OFFSET_ACTION,
  SUBTITLE_SIZE_ACTION,
  VOLUME_ACTION,
} from '../../../../shared/models/common-models';

export interface VideoPlayerControllerOptions {
  defaultSubtitlesSize: number;
  subtitleSizeJump: number;
  volumeJump: number;
}

@Options({})
export default class VideoPlayerController
  extends Vue
  implements PlayerController
{
  private subtitlesStyle: CssRules = {};
  private subtitleManager!: SubtitleManager;
  private video!: HTMLVideoElement;
  private subtitlesTrack!: HTMLTrackElement;
  private toasty!: SimpleToasty;
  private options!: VideoPlayerControllerOptions;

  init(
    video: HTMLVideoElement,
    subtitlesTrack: HTMLTrackElement,
    toasty: SimpleToasty,
    options: VideoPlayerControllerOptions
  ): void {
    this.video = video;
    this.subtitlesTrack = subtitlesTrack;
    this.toasty = toasty;
    this.options = options;

    this.subtitleManager = new SubtitleManager(this.subtitlesTrack);
    this.subtitlesSizeValue = this.options.defaultSubtitlesSize;
    this.updateSubtitlesSize();
    this.updateSubtitlesOffset();
  }

  private subtitlesOffsetValue = -1;

  get subtitlesOffset(): number {
    return this.subtitlesOffsetValue;
  }
  set subtitlesOffset(value: number) {
    this.subtitlesOffsetValue = value;
    this.updateSubtitlesOffset();
  }

  private subtitlesSizeValue!: number;

  get subtitlesSize(): number {
    return this.subtitlesSizeValue;
  }

  set subtitlesSize(value: number) {
    this.subtitlesSizeValue = value;
    this.updateSubtitlesSize();
  }

  get currentTime(): number {
    return this.video.currentTime;
  }

  set currentTime(value: number) {
    this.video.currentTime = value;
  }

  async playPause(): Promise<void> {
    if (this.video.paused) await this.video.play();
    else this.video.pause();
  }

  async backward(seconds: number): Promise<void> {
    this.video.currentTime -= seconds;
    this.toasty.showToasty(`Backwarding ${seconds} seconds`);
  }

  async forward(seconds: number): Promise<void> {
    this.video.currentTime += seconds;
    this.toasty.showToasty(`Forwarding ${seconds} seconds`);
  }

  changeSubtitlesOffset(direction: SUBTITLE_OFFSET_ACTION): void {
    this.subtitlesOffset += direction === 'down' ? 1 : -1;
  }

  changeSubtitlesSize(action: SUBTITLE_SIZE_ACTION): void {
    const delta =
      action === 'increase'
        ? this.options.subtitleSizeJump
        : -this.options.subtitleSizeJump;

    // TODO: This is a bug where subtitlesValue += delta is not working, couldn't determine why, this is a work-around
    this.subtitlesSizeValue += delta;
    this.updateSubtitlesSize();
  }

  changeVolume(action: VOLUME_ACTION): void {
    const volume = Math.min(
      Math.max(
        this.video.volume +
          (action === 'up'
            ? this.options.volumeJump
            : -this.options.volumeJump),
        0
      ),
      1
    );

    this.video.volume = volume;
    this.toasty.showToasty(
      `Volume changed to: ${Math.round(this.video.volume * 100)}%`
    );
  }

  resetSubtitlesStyling(): void {
    this.subtitleManager.reset();
    this.subtitlesSize = this.options.defaultSubtitlesSize;
  }

  toggleFullscreen(): Promise<void> {
    if (!document.fullscreenElement) return this.video.requestFullscreen();
    return document.exitFullscreen();
  }

  setSubtitlesBackground(color: string): void {
    this.updateSubtitlesStyling({ 'background-color': color });
  }

  private updateSubtitlesSize(): void {
    if (this.subtitlesSizeValue)
      this.updateSubtitlesStyling({
        'font-size': `${this.subtitlesSizeValue.toString()}px`,
      });
  }

  private updateSubtitlesStyling(newStyling: CssRules): void {
    this.subtitlesStyle = { ...this.subtitlesStyle, ...newStyling };
    SubtitlesService.setSubtitlesStyling(this.subtitlesStyle);
  }

  private updateSubtitlesOffset(): void {
    const value =
      this.subtitlesOffsetValue === -1 ? 'auto' : this.subtitlesOffsetValue;
    this.subtitleManager.line = value;
  }
}
