
import { of, Subscription } from 'rxjs';
import { Options, Vue } from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { fromEvent } from 'rxjs';
import { map, timeoutWith, repeat } from 'rxjs/operators';
import FilesSelector from '../FilesSelector.vue';
import AppService from '@/services/app.service';
import QRCode from '@/components/QRCode.vue';
import SubtitlesService from '@/services/subtitles.service';
import Dialog from '@/components/Dialog.vue';
import SimpleToasty from '@/components/SimpleToasty.vue';
import VideoPlayerController from '../VideoPlayer/VideoPlayerController.vue';
import { CONTROLLER_ACTION } from '../../../../shared/models/socket-events';
import { applyControllerAction } from '@/utils/player-controller-helper';
import CastButton from '../CastButton.vue';
import { event } from 'vue-gtag';

const SUBTITLES_SIZE_JUMP = +(process.env[
  'VUE_APP_SUBTITLE_SIZE_JUMP'
] as string);

const SUBTITLES_FONT_SIZE = +(process.env[
  'VUE_APP_SUBTITLE_FONT_SIZE'
] as string);

const VOLUME_JUMP = +(process.env['VUE_APP_VOLUME_JUMP'] as string);

@Options({
  components: {
    FilesSelector,
    QRCode,
    Dialog,
    SimpleToasty,
    VideoPlayerController,
    CastButton,
  },
  emits: [
    'media-changed',
    'subtitles-changed',
    'video-playing',
    'video-paused',
    'video-playtimechanged',
  ],
})
export default class VideoPlayer extends Vue {
  @Prop(File) readonly file!: File;
  @Prop(File) readonly subtitlesFile!: File | null;
  isUserActive = false;

  private subscription = new Subscription();

  get controller(): VideoPlayerController {
    return this.$refs.controller as VideoPlayerController;
  }

  get hasMedia(): boolean {
    return !!this.video.src;
  }

  // The current played video file
  private playedVideoFileValue: File | null = null;

  get playedVideoFile(): File | null {
    return this.playedVideoFileValue;
  }

  // The currently played video subtitles
  private playedVideoSubtitlesFileValue: File | null = null;

  get playedVideoSubtitlesFile(): File | null {
    return this.playedVideoSubtitlesFileValue;
  }

  get duration(): number {
    return this.video.duration;
  }

  get currentTime(): number {
    return this.video.currentTime;
  }

  set currentTime(value: number) {
    this.video.currentTime = value;
  }

  private get qrURL(): string {
    return AppService.qrURL;
  }

  private get toasty(): SimpleToasty {
    return this.$refs.toasty as SimpleToasty;
  }

  onShowInstructions(): void {
    event('click', { event_category: 'instructions' });
    const dialog = this.$refs.instructionsDialog as Dialog;
    dialog.showDialog();
  }

  @Watch('file')
  onFileChange(currentValue: File): void {
    if (currentValue) this.playVideoFile(currentValue);
  }

  @Watch('subtitlesFile')
  async onSubtitlesFileChange(currentValue: File): Promise<void> {
    this.setSubtitles(currentValue);
  }

  get video(): HTMLVideoElement {
    return this.$refs.video as HTMLVideoElement;
  }

  get subtitlesTrack(): HTMLTrackElement {
    return this.$refs.subtitlesTrack as HTMLTrackElement;
  }

  canPlayFile(file: File): boolean {
    return !!this.video.canPlayType(file.type);
  }

  supportsSubtitles(file: File): boolean {
    return SubtitlesService.supportsFormat(file);
  }

  onSubtitleSelected(files: File[]): void {
    event('subtitle_selected', { event_category: 'media-change' });
    const subtitleFile = files.length > 0 && files[0];
    if (subtitleFile) this.setSubtitles(files[0]);
  }

  onVideoSelected(files: File[]): void {
    event('video_selected', { event_category: 'media-change' });
    const videoFile = files.length > 0 && files[0];
    if (videoFile) this.playVideoFile(files[0]);
  }

  onVideoPlaying(ev: Event): void {
    event('play', { event_category: 'video-playback' });
    this.$emit('video-playing', ev);
  }

  onVideoPause(ev: Event): void {
    event('pause', { event_category: 'video-playback' });
    this.$emit('video-paused', ev);
  }

  onVideoTimeChanged(ev: Event): void {
    this.$emit('video-playtimechanged', ev);
  }

  async playVideoFile(file: File): Promise<void> {
    if (this.canPlayFile(file)) {
      const fileURL = URL.createObjectURL(file);
      this.video.src = fileURL;
      await this.video.play();
      this.playedVideoFileValue = file;

      this.$emit('media-changed', file);
      this.toasty.showToasty(`Video loaded: ${file.name}`);
    } else {
      this.toasty.showToasty({
        html: `Media file not supported: ${file.name}`,
        state: 'error',
      });
    }
  }

  async setSubtitles(file: File): Promise<void> {
    if (file && this.supportsSubtitles(file)) {
      this.toasty.showToasty({
        html: `Loading subtitles...`,
        state: 'loading',
      });

      try {
        const vttFile = await SubtitlesService.getVtt(file);
        const subtitlesURL = URL.createObjectURL(vttFile);
        this.subtitlesTrack.src = subtitlesURL;
        this.playedVideoSubtitlesFileValue = file;

        this.$emit('subtitles-changed', file);
        this.toasty.showToasty(`Successfully loaded subtitles: ${file.name}`);
      } catch (error) {
        this.toasty.showToasty({
          html: `Failed to load subtitles: ${file.name}`,
          state: 'error',
        });
      }
    } else {
      this.toasty.showToasty({
        html: `Subtitles are not supported: ${file.name}`,
        state: 'error',
      });
    }
  }

  clearSubtitles(): void {
    this.subtitlesTrack.src = '';
  }

  mounted(): void {
    this.subscription.add(this.subscribeToMouseMove());
    this.subscription.add(this.subscribeToKeyboardEvents());

    this.toasty.showToasty({
      state: 'error',
      html: 'No video loaded',
      showTime: -1,
    });

    // Initialize the controller
    this.controller.init(this.video, this.subtitlesTrack, this.toasty, {
      defaultSubtitlesSize: SUBTITLES_FONT_SIZE,
      subtitleSizeJump: SUBTITLES_SIZE_JUMP,
      volumeJump: VOLUME_JUMP,
    });
  }

  private subscribeToMouseMove() {
    return fromEvent(document, 'mousemove')
      .pipe(
        map(() => true),
        timeoutWith(1000, of(false)),
        repeat()
      )
      .subscribe((isActive) => {
        this.isUserActive = isActive;
      });
  }

  private subscribeToKeyboardEvents(): Subscription {
    return fromEvent(document, 'keydown').subscribe((event) => {
      const keyEvent = event as KeyboardEvent;
      const mappedKeyToAction = this.mapKeyToAction(keyEvent.key);

      // Make sure there is mapping for this key
      if (mappedKeyToAction) {
        const { action, value } = mappedKeyToAction;
        applyControllerAction(this.controller, action, value);
      }
    });
  }

  private mapKeyToAction(key: string):
    | {
        action: CONTROLLER_ACTION;
        value?: string | number;
      }
    | undefined {
    const keysToActions: {
      [action: string]: {
        action: CONTROLLER_ACTION;
        value?: string | number;
      };
    } = {
      '=': { action: 'subtitles-size', value: 'increase' },
      '+': { action: 'subtitles-size', value: 'increase' },
      '-': { action: 'subtitles-size', value: 'decrease' },
      ArrowUp: { action: 'subtitles-offset', value: 'up' },
      ArrowDown: { action: 'subtitles-offset', value: 'down' },
      Backspace: { action: 'subtitles-reset' },
      f: { action: 'toggle-fullscreen' },
      '[': { action: 'change-volume', value: 'down' },
      ']': { action: 'change-volume', value: 'up' },
    };

    return keysToActions[key];
  }

  unmounted(): void {
    this.subscription.unsubscribe();
  }
}
