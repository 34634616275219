/**
 * Allow handling subtitles positioning easily.
 *
 * @export
 * @class SubtitleManager
 */
export class SubtitleManager {
  constructor(public track: HTMLTrackElement) {}

  private _line: LineAndPositionSetting = 'auto';

  get line(): LineAndPositionSetting {
    return this._line;
  }

  set line(value: LineAndPositionSetting) {
    this._line = value;

    const cues = this.track.track.cues;

    if (cues) {
      for (const cue of cues) {
        const vttCue = cue as VTTCue;
        vttCue.line = this._line;
      }
    }
  }

  reset(): void {
    this.line = 'auto';
  }
}
