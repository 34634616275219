import apiService from './api.service';

export interface CssRules {
  [name: string]: string;
}

/**
 * Allows handling subtitles in terms of importing subtitles from different formats and styling them.
 * You can find out more information about styling the subtitles here:
 * http://ronallo.com/blog/html5-video-caption-cue-settings-tester/
 * and here:
 * http://ronallo.com/demos/webvtt-cue-settings/
 *
 * @export
 * @class SubtitlesService
 */
export class SubtitlesService {
  // The ::cue is the tag that needs to be modified in order to affect the subtitles inside the video
  private _cueStyle!: HTMLStyleElement;

  async getVtt(file: File): Promise<File> {
    if (file.name.endsWith('.srt')) {
      return apiService.getVtt(file);
    } else if (file.name.endsWith('.vtt')) return file;

    throw new Error('The provided file is not supported!');
  }

  supportsFormat(file: File): boolean {
    const supportedFormats = ['.srt', '.vtt'];
    for (const format of supportedFormats)
      if (file.name.endsWith(format)) return true;

    return false;
  }

  /**
   * Changes the subtitles CSS styling according to the provided css rules.
   *
   * @param {{ [name: string]: string }} cssRules
   * @memberof SubtitlesService
   */
  setSubtitlesStyling(cssRules: CssRules): void {
    const styleHTML = this.generateCSStyleHTML('::cue', cssRules);

    if (!this._cueStyle) {
      this._cueStyle = document.createElement('style');
      this._cueStyle.setAttribute('type', 'text/css');
      document.head.appendChild(this._cueStyle);
    }

    this._cueStyle.innerHTML = styleHTML;
  }

  private generateCSStyleHTML(htmlTag: string, cssRules: CssRules): string {
    const ruleHTML = `${htmlTag} { ${Object.keys(cssRules)
      .map((r) => `${r}: ${cssRules[r]};`)
      .join('\n')}}`;

    return ruleHTML;
  }
}

export default new SubtitlesService();
