
import { Options, Vue } from 'vue-class-component';

import ChromeCaster from '@/components/ChromeCaster.vue';

@Options({
  components: {
    ChromeCaster,
  },
})
export default class Home extends Vue {
  private file: File | null = null;
  sessionId!: string;

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.item(0);
    this.file = file as File;
  }

  created(): void {
    this.sessionId = this.$route.meta.sessionId as string;
  }
}
