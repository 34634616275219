import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "me-3"
}
const _hoisted_2 = { class: "flex-fill" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.visible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["simple-toasty d-flex", { 'simple-toasty-state': _ctx.state }])
          }, [
            (_ctx.state !== 'none')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.state === 'loading')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 0,
                        icon: "circle-notch",
                        spin: ""
                      }))
                    : _createCommentVNode("v-if", true),
                  (_ctx.state === 'info')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 1,
                        icon: "info-circle"
                      }))
                    : _createCommentVNode("v-if", true),
                  (_ctx.state === 'error')
                    ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                        key: 2,
                        icon: "exclamation-triangle"
                      }))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.customHtml)
                ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                : _createCommentVNode("v-if", true),
              (_ctx.customHtml)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    innerHTML: _ctx.customHtml
                  }, null, 8 /* PROPS */, _hoisted_3))
                : _createCommentVNode("v-if", true)
            ])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }))
}