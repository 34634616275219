
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  emits: ['files-selected'],
})
export default class FileSelectors extends Vue {
  @Prop(Boolean) allowMultipleFiles!: boolean;

  get fileInput(): HTMLInputElement {
    return this.$refs.file as HTMLInputElement;
  }

  onSelectFile(): void {
    this.fileInput.click();
  }

  onFileChange(): void {
    this.$emit('files-selected', this.fileInput.files);
  }
}
